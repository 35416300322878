<template>
  <div class="trade-root-container">
    <span class="page-title">交易查询</span>

    <div class="trade-board-container">
      <div class="trade-board-top-container">
        <div class="trade-board-item">
          <div style="color: #cccccc; font-size: 14px; font-weight: 500">
            累计收入
          </div>
          <div style="
              color: #ffffff;
              font-size: 32px;
              font-weight: bold;
              margin-top: 10px;
              margin-bottom: 30px;
            ">
            ￥{{ totalInAmountFormat.integer }}.<span style="font-size: 28px">{{
              totalInAmountFormat.decimal
            }}</span>
          </div>
          <div style="color: #d3d3d3; font-size: 15px">
            合计收入 {{ totalInCount }} 笔
          </div>
        </div>
        <div class="trade-board-item">
          <div style="color: #cccccc; font-size: 14px; font-weight: 500">
            累计支出
          </div>
          <div style="
              color: #ffffff;
              font-size: 32px;
              font-weight: bold;
              margin-top: 10px;
              margin-bottom: 30px;
            ">
            ￥{{ totalOutAmountFormat.integer }}.<span style="font-size: 28px">{{ totalOutAmountFormat.decimal }}</span>
          </div>
          <div style="color: #d3d3d3; font-size: 15px">
            合计支出 {{ totalOutCount }} 笔
          </div>
        </div>
      </div>
      <div class="trade-search-container">
        <Icon type="md-search" size="17" style="margin-left: 25px" />
        <input style="
            margin-left: 10px;
            background-color: transparent;
            border: none;
            width: 300px;
          " placeholder="搜索" v-model="keyword" @keyup.enter="getList" />
      </div>
    </div>

    <div class="trade-list-row">
      <span style="font-size: 12px; font-weight: bold; user-select: none">最近的用工相关</span>
      <span style="color: #417da6; font-size: 12px; cursor: pointer" @click="showTradeModal">查看全部</span>
    </div>
    <div class="trade-list-container">
      <trade-item v-for="item in logList" :key="item.card_log_id" :data-source="item" />
    </div>
    <Page style="align-self: flex-end; margin-bottom: 15px" @on-change="onPageIndexChange" :current="pageIndex + 1"
      :total="totalCount" :page-size="pageSize" />

    <asset-trade-modal ref="assetTradeModal" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { numberFormat } from "../../utils/numberHelper";
import TradeItemVue from "../Contact/Item/TradeItem.vue";
import AssetTradeModalVue from "../Asset/Modal/AssetTradeModal.vue";
export default {
  components: {
    "trade-item": TradeItemVue,
    "asset-trade-modal": AssetTradeModalVue,
  },
  data() {
    return {
      keyword: "",

      pageSize: 10,
      pageIndex: 0,
      totalCount: 0,
      totalInCount: 1000,
      totalOutCount: 1000,
      totalInAmount: 10000000,
      totalOutAmount: 10000000,

      logList: [],
    };
  },
  mounted() {
    this.tradeGetCountAction({
      employer_id: localStorage.getItem("employer_id"),
    })
      .then((res) => {
        this.totalInCount = res.in_total_count;
        this.totalInAmount =
          res.in_total_salary_amount + res.in_total_service_amount;
        this.totalOutCount = res.out_total_count;
        this.totalOutAmount = Math.abs(res.out_total_salary_amount);
      })
      .catch((error) => {
        console.error(error);
        this.$Message.error(error);
      });

    this.getList();
  },
  computed: {
    totalInAmountFormat() {
      return numberFormat(this.totalInAmount);
    },
    totalOutAmountFormat() {
      return numberFormat(this.totalOutAmount);
    },
  },
  methods: {
    ...mapActions({
      tradeGetCountAction: "tradeGetCount",
      assetGetCardLogAction: "assetGetCardLog",
    }),
    showTradeModal() {
      this.$refs.assetTradeModal.open();
    },
    getList() {
      this.assetGetCardLogAction({
        employer_id: localStorage.getItem("employer_id"),
        type: 0,
        page_index: this.pageIndex,
        page_size: this.pageSize,
        search_content: this.keyword,
      })
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.logList = res.data;
          this.totalCount = res.total_count;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    onPageIndexChange(index) {
      this.pageIndex = index - 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.trade-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 150px;
  background-color: #f9f9f9;
  /* overflow: hidden; */
}
.trade-board-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  background-color: #2b303b;
  width: 100%;
  height: 310px;
  border-radius: 15px;
}
.trade-search-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 370px;
  height: 45px;
  border-radius: 25px;
  background-color: #f1f1f1;
}
.trade-search-container input:focus {
  outline: none;
}
.trade-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
}
.trade-list-container {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  margin-bottom: 20px;
}
.trade-board-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: stretch;
}
.trade-board-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>