<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="asset-income-modal-root-container">
      <div class="asset-income-row">
        <div>{{ dataSource.create_time }}</div>
        <div>#预付款交易#已完成</div>
      </div>
      <my-avatar
        class="asset-income-center"
        style="margin-top: 40px"
        :img_url="icons.ysLogo"
        :name="dataSource.employer_name"
        :size="78"
      />
      <span
        class="asset-income-center"
        style="
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          color: #000000;
        "
        >游用工卡</span
      >
      <span
        class="asset-income-center"
        style="
          font-size: 13px;
          font-weight: 500;
          color: #5f5f5f;
          margin: 10px 0;
        "
        >游隼企业管理(山东)有限公司</span
      >
      <fee
        class="asset-income-center"
        :amount="dataSource.salary_amount"
        :primarySize="18"
        :secondarySize="15"
      />
      <Divider />
      <div class="asset-income-row" style="margin-bottom: 10px">
        <div class="asset-income-key">交易ID</div>
        <div class="asset-income-val">{{ dataSource.card_log_id }}</div>
      </div>
      <div class="asset-income-row" style="margin-bottom: 10px">
        <div class="asset-income-key">付款主体</div>
        <div class="asset-income-val">{{ dataSource.company_name }}</div>
      </div>
      <div class="asset-income-row" style="margin-bottom: 40px">
        <div class="asset-income-key">付款方式</div>
        <div class="asset-income-val">应用外转账</div>
      </div>
      <div class="asset-income-row" style="margin-bottom: 10px">
        <div class="asset-income-key">付款金额</div>
        <fee
          fee-type="in"
          :amount="dataSource.salary_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="asset-income-row" style="margin-bottom: 40px">
        <div class="asset-income-key">费用</div>
        <fee
          :amount="dataSource.service_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="asset-income-row" style="margin-bottom: 100px">
        <div class="asset-income-key">#游用工卡到账#</div>
        <fee
          fee-type="in"
          :amount="dataSource.salary_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      isShowModal: false,

      icons: icon,
      dataSource: {},
    };
  },
  methods: {
    ...mapActions({}),
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
  },
};
</script>

<style scoped>
.asset-income-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 30px 50px;
}
.asset-income-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.asset-income-center {
  align-self: center;
}
.asset-income-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.asset-income-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.asset-income-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
</style>