<template>
  <div class="slide-tabbar-root-container">
    <div class="slide-tabbar-container">
      <div
        class="slide-tabbar-item-container"
        v-for="(item, index) in tabs"
        :key="index"
        @click="change(index)"
        :ref="'tabbar-' + index"
      >
        <span
          :class="
            activeIndex == index ? 'slide-tabbar-active' : 'slide-tabbar-normal'
          "
          :style="[
            { transition: 'all 0.1s ease', 'user-select': 'none' },
            tabItemClass,
          ]"
          >{{ item.name }}</span
        >
        <Badge
          v-if="item.count > 0"
          class="slide-tabbar-item-count-container"
          :count="item.count"
        ></Badge>
      </div>
    </div>
    <div class="slide-container" style="position: relative">
      <div class="slide-bar-container" v-if="showBar"></div>
      <div
        class="slide-item slide-bar-slider"
        :style="{ width: sliderWidth + 'px', left: sliderPaddingLeft + 'px' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    showBar: {
      type: Boolean,
      default: false,
    },
    tabItemClass: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeIndex: 0,
      sliderWidth: 0,
      sliderPaddingLeft: 0,
    };
  },
  mounted() {
    this.sliderWidth = this.$refs["tabbar-0"][0].offsetWidth;
  },
  methods: {
    change(index) {
      if (index == this.activeIndex) return;
      this.activeIndex = index;
      this.$emit("on-change", this.activeIndex);
      setTimeout(() => {
        this.sliderWidth = this.$refs["tabbar-" + index][0].offsetWidth;
        this.sliderPaddingLeft = this.$refs["tabbar-" + index][0].offsetLeft;
      }, 100);
    },
    switchTo(index) {
      this.activeIndex = index;
      this.sliderWidth = this.$refs[
        "tabbar-" + this.activeIndex
      ][0].offsetWidth;
      this.sliderPaddingLeft = this.$refs[
        "tabbar-" + this.activeIndex
      ][0].offsetLeft;
    },
  },
};
</script>

<style scoped>
.slide-tabbar-root-container {
  display: flex;
  flex-direction: column;
}
.slide-tabbar-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
}
.slide-tabbar-item-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 50px;
}
.slide-tabbar-item-container:last-child {
  margin-right: 0;
}
.slide-tabbar-item-count-container {
  position: absolute;
  top: -10px;
  right: -15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  background-color: #e75959;
  border-radius: 50%;
  transform: scale(0.7);
}
.slide-tabbar-active {
  font-size: 13px;
  font-weight: 500;
  color: #141414;
  cursor: pointer;
}
.slide-tabbar-normal {
  font-size: 13px;
  font-weight: 500;
  color: #646464;
  cursor: pointer;
}
.slide-bar-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  border: 1px solid #efefef;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); */
}
.slide-bar-slider {
  position: absolute;
  top: 0;
  border: 1px solid #f8cf7c;
  transition: all 0.2s ease;
}
</style>