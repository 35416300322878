<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="asset-detail-modal-root-container">
      <div class="asset-detail-row">
        <div>{{ createTime }}</div>
        <div>#用工支出#已完成</div>
      </div>
      <my-avatar
        class="asset-detail-center"
        style="margin-top: 40px"
        :img_url="dataSource.img_url"
        :name="dataSource.employer_name"
        :size="78"
      />
      <div
        class="asset-detail-center"
        style="
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          color: #000000;
        "
      >
        {{ dataSource.to_client_user_name }}
      </div>
      <div
        class="asset-detail-center"
        style="font-size: 12px; color: #626262; margin-top: 10px"
      >
        身份证后4位:{{ idNumLastFourStr }}
      </div>
      <fee
        class="asset-detail-center"
        fee-type="out"
        :amount="salaryAmount"
        :primarySize="18"
        :secondarySize="15"
      ></fee>
      <Divider />
      <div class="asset-detail-row" style="margin-bottom: 10px">
        <div class="asset-detail-key">交易ID</div>
        <div class="asset-detail-val">{{ dataSource.card_log_id }}</div>
      </div>
      <div class="asset-detail-row" style="margin-bottom: 10px">
        <div class="asset-detail-key">付款主体</div>
        <div class="asset-detail-val">{{ dataSource.company_name }}</div>
      </div>
      <div class="asset-detail-row" style="margin-bottom: 10px">
        <div class="asset-detail-key">付款方式</div>
        <div class="asset-detail-val">预付款/卡</div>
      </div>
      <div class="asset-detail-row" style="margin-bottom: 35px">
        <div class="asset-detail-key">付款账户</div>
        <div class="asset-detail-val">游用工卡</div>
      </div>
      <div class="asset-detail-row" style="margin-bottom: 10px">
        <div class="asset-detail-key">付款金额</div>
        <fee
          fee-type="out"
          :amount="salaryAmount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <!-- <div class="asset-detail-row" style="margin-bottom: 10px;">
        <div class="asset-detail-key">其中：所得税</div>
        <div class="asset-detail-val">￥{{ tax_amount }}</div>
      </div>
      <div class="asset-detail-row" style="margin-bottom: 50px;">
        <div class="asset-detail-key">费用</div>
        <div class="asset-detail-val">￥{{ fee_amount }}</div>
      </div>-->
      <div class="asset-detail-row">
        <div class="asset-detail-key" style="margin-bottom: 10px">位置</div>
        <div class="asset-detail-val">
          {{ dataSource.to_client_user_position }}
        </div>
      </div>
      <div class="asset-detail-key" style="margin-bottom: 10px">付款事由</div>
      <div class="asset-detail-pay-reason" @click="showPlanModal">
        <div class="asset-detail-pay-reason-container">
          <my-avatar
            style="margin-left: 15px; margin-right: 15px"
            img_url
            :name="dataSource.project_name"
          ></my-avatar>
          <div>
            <div style="font-size: 14px; font-weight: 500; color: #505050">
              来自{{ dataSource.project_name }}
            </div>
            <div style="font-size: 12px; font-weight: 500; color: #828282">
              编号#{{ dataSource.pay_plan_id }}#的付款计划
            </div>
          </div>
        </div>
        <img :src="icons.rightArrow" />
      </div>
      <Button
        size="large"
        type="primary"
        class="asset-detail-center"
        style="
          background-color: #8aa3c0;
          border-color: #8aa3c0;
          margin-top: 30px;
        "
        @click="getPdf"
        >下载交易回单</Button
      >

      <plan-pay-modal ref="planPayModal" />
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
import PlanPayModal from "../../Plan/Modal/PlanPayModal";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "plan-pay-modal": PlanPayModal,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,
      dataSource: {},
    };
  },
  methods: {
    ...mapActions({
      assetGetPdfAction: "assetGetPdf",
      assetGetCardLogDetailAction: "assetGetCardLogDetail",
    }),
    open(card_log_id) {
      this.assetGetCardLogDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        card_log_id: card_log_id,
      })
        .then((res) => {
          this.dataSource = res;
          this.isShowModal = true;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    showPlanModal() {
      this.$refs.planPayModal.open({
        type: this.dataSource.type,
        pay_plan_id: this.dataSource.pay_plan_id,
      });
    },
    getPdf() {
      this.assetGetPdfAction({
        card_log_id: this.dataSource.card_log_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
  },
  computed: {
    idNumLastFourStr: function () {
      return this.dataSource.to_client_user_idnum.substring(
        this.dataSource.to_client_user_idnum.length - 4
      );
    },
    salaryAmount: function () {
      return Math.abs(this.dataSource.salary_amount);
    },
    createTime: function () {
      return moment(this.dataSource.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.asset-detail-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 25px 50px 30px 50px;
}
.asset-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.asset-detail-center {
  align-self: center;
}
.asset-detail-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.asset-detail-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.asset-detail-pay-reason {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fbfae8;
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
  cursor: pointer;
}
.asset-detail-pay-reason-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}
</style>