<template>
  <div class="trade-item-root-container" @click="showDetail">
    <div class="trade-item-left-container">
      <my-avatar
        v-if="dataSource.type == 2"
        :size="50"
        style="margin: 15px 15px 15px 30px"
        :name="dataSource.to_client_user_name"
        :img_url="null"
      />
      <my-avatar
        v-else
        :size="50"
        style="margin: 15px 15px 15px 30px"
        :name="dataSource.employer_name"
        :img_url="null"
      />
    </div>
    <div class="trade-item-main-container" v-if="dataSource.type == 1">
      <span style="font-size: 15px; font-weight: bold; color: #000000">
        {{ dataSource.company_name }}
        <span
          style="
            font-size: 12px;
            font-weight: 300;
            color: #626262;
            margin-left: 10px;
          "
          >@{{ dataSource.card_issuer }}</span
        >
      </span>
      <span style="font-size: 12px; color: #626262">#预付款/卡#</span>
    </div>
    <div v-else class="trade-item-main-container">
      <span style="font-size: 15px; font-weight: bold; color: #000000">
        {{ dataSource.to_client_user_name }}
        <span
          style="
            font-size: 12px;
            font-weight: 300;
            color: #626262;
            margin-left: 10px;
          "
          >身份证后4位:{{ idNumLastFourStr }}</span
        >
      </span>
      <div class="trade-item-row">
        <div style="font-size: 12px; color: #626262; margin-right: 15px">
          #用工支出#
        </div>
        <Icon type="md-pin" :size="12" />
        <span style="font-size: 12px; color: #363839">{{
          dataSource.to_client_user_position
        }}</span>
      </div>
    </div>
    <div class="trade-item-right-container">
      <fee
        :fee-type="dataSource.type == 1 ? 'in' : 'out'"
        :amount="
          dataSource.type == 1
            ? dataSource.salary_amount + dataSource.service_amount
            : dataSource.salary_amount
        "
        :primarySize="18"
        :secondarySize="15"
      ></fee>
      <div style="font-size: 12px; color: #7d7d7d">
        {{ dataSource.create_time }}
      </div>
    </div>

    <asset-detail-modal ref="assetDetailModal" />
    <asset-income-modal ref="assetIncomeModal" />
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import AssetDetailModal from "../../Asset/Modal/AssetDetailModal";
import AssetIncomeModal from "../../Asset/Modal/AssetIncomeModal";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "asset-detail-modal": AssetDetailModal,
    "asset-income-modal": AssetIncomeModal,
  },
  methods: {
    showDetail() {
      if (this.dataSource.type == 1) {
        this.$refs.assetIncomeModal.open(this.dataSource);
      } else {
        this.$refs.assetDetailModal.open(this.dataSource.card_log_id);
      }
    },
  },
  computed: {
    idNumLastFourStr: function () {
      return this.dataSource.to_client_user_idnum.substring(
        this.dataSource.to_client_user_idnum.length - 4
      );
    },
  },
};
</script>

<style scoped>
.trade-item-root-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 1px #dddddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
}
.trade-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.trade-item-left-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.trade-item-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.trade-item-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;
}
.trade-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}
</style>