<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="asset-trade-modal-root-container">
      <div class="page-title">全部交易</div>
      <slider-tabbar
        style="margin-top: 25px"
        :tabs="tabs"
        show-bar
        @on-change="onBarChange"
      ></slider-tabbar>

      <div class="asset-trade-modal-list">
        <trade-item
          v-for="item in logList"
          :key="item.card_log_id"
          :data-source="item"
        ></trade-item>
      </div>

      <Page
        style="align-self: flex-end"
        @on-change="onPageIndexChange"
        :current="pageIndex + 1"
        :page-size="pageSize"
        :total="totalCount"
      />
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import TradeItem from "../../Contact/Item/TradeItem";
import SliderTabbar from "../../../components/SlideTabbar";
export default {
  components: {
    "trade-item": TradeItem,
    "slider-tabbar": SliderTabbar,
  },
  data() {
    return {
      isShowModal: false,

      id: 0,
      pageSize: 10,
      pageIndex: 0,
      totalCount: 0,
      currentIndex: 0,
      tabs: [
        {
          name: "全部类型",
        },
        {
          name: "预付款/卡",
        },
        {
          name: "用工支出",
        },
      ],
      logList: [],
    };
  },
  methods: {
    ...mapActions({
      assetGetCardLogAction: "assetGetCardLog",
    }),
    open() {
      this.isShowModal = true;
      this.getLog();
    },
    getLog() {
      this.assetGetCardLogAction({
        employer_id: localStorage.getItem("employer_id"),
        type: this.currentIndex,
        page_index: this.pageIndex,
        page_size: this.pageSize,
        search_content: "",
      })
        .then((res) => {
          for (let item of res.data) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.logList = res.data;
          this.totalCount = res.total_count;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    onBarChange(index) {
      this.currentIndex = index;
      this.pageIndex = 0;
      this.getLog();
    },
    onPageIndexChange(index) {
      this.pageIndex = index - 1;
      this.getLog();
    },
  },
};
</script>

<style scoped>
.asset-trade-modal-root-container {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 140px;
  overflow: hidden;
}
.asset-trade-list {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}
.asset-trade-modal-list {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>